import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { OpenIcon, EraseIcon, SelectedIcon } from './icons'
import {
  escapeChars,
  getPrevIndex,
  getNextIndex,
  scrollToFocusedChild
} from './functions'

import './select-box.css'

import { emptyFn } from '../../lib/fn-utils'

import { safeValue } from '../../lib/object-utils'

const SelectBox = ({
  id,
  cssClass = '__select-box',
  value = '',
  valueField = '',
  labelField = '',
  required = false,
  requiredText = 'El campo es obligatorio',
  label = '',
  secondLabel = '',
  message = '',
  placeholder = 'Seleccione una opción',
  noOptionsText = 'No hay opciones',
  noResultsText = 'No hay resultados',
  disabled = false,
  options = [],
  maxLength = 256,
  disabledSearch = false,
  autoFocus = false,
  onChange = emptyFn,
  onFocus = emptyFn,
  onLostFocus = emptyFn,
  tabIndex = null,
  width = '100%'

}) => {

  // const selected =
  //   options.find(option => safeValue(option, valueField, '') === value)[valueField];

  const [open, setOpen] = useState(autoFocus)
  const [selectedOption, setSelectedOption] = useState(
    options.find(option => safeValue(option, valueField, '') === value)
  )
  const [searchText, setSearchText] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [focusOption, setFocusOption] = useState()
  const [showRequired, setShowRequired] = useState(false)

  useEffect(() => {
    const selOption = options.find(option => safeValue(option, valueField, '') === value);
    const selValue = selOption ? selOption[valueField] : '';
    const selLabel = selOption ? selOption[labelField] : '';

    setSelectedOption(selValue);
    setSearchText(selLabel);
  }, [options, value, valueField, labelField])

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => escapeChars(option[labelField]).includes(escapeChars(searchText)))
    )
  }, [options, searchText, labelField])

  const handleSearchChange = (value) => {
    setSearchText(value.trimStart().substring(0, maxLength))
  }

  const handleOpen = () => {
    setOpen(true)
    setShowRequired(false)
  }

  const close = () => {
    // setSearchText('')
    setFocusOption(null)
    setOpen(false)
  }

  const handleClose = () => {

    required && setShowRequired(!selectedOption)
    close()
  }

  const handleChange = (option) => {

    const selectedValue = safeValue(option, valueField, '');
    const selectedLabel = safeValue(options, labelField, '');

    setSelectedOption(selectedValue);
    onChange(selectedValue, option);
    setSearchText(selectedLabel)
    close();

  }

  const handleKey = (e) => {
    const { keyCode } = e
    if (disabledSearch) e.preventDefault()
    if (keyCode === 8 && !searchText) {
      e.preventDefault()
      !required && setSelectedOption()
      return
    }
    if (keyCode === 13) {
      e.preventDefault()
      open ? handleChange(filteredOptions[focusOption]) : handleOpen()
      return
    }
    if (keyCode === 38 || keyCode === 40) {
      const optionsElement = e.target?.nextSibling?.nextSibling
      e.preventDefault()
      handleOpen()
      const newFocus =
        keyCode === 38
          ? getPrevIndex(focusOption, filteredOptions)
          : getNextIndex(focusOption, filteredOptions)
      scrollToFocusedChild(optionsElement, newFocus)
      setFocusOption(newFocus)
    }
  }

  const isInvalid = () => {
    return showRequired
  }

  const getClasses = () => {
    return `${isInvalid() ? 'invalid' : ''} ${disabledSearch ? 'no-search' : ''
      } ${selectedOption ? 'selected' : ''}`
  }

  const getListOfOptions = () => {
    return (
      <ul>
        {filteredOptions.map((option, index) => {
          const optionSelected = selectedOption === option[valueField]
          const optionClasses = `${focusOption === index ? 'focus' : ''} ${optionSelected ? 'selected' : ''
            }`
          return (
            <li
              className={optionClasses}
              onMouseOver={() => setFocusOption(index)}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => handleChange(option)}
              key={`${id}-opt-${index}`}
            >
              <span>{option[labelField]}</span>
              {optionSelected ? (
                <span className='icon'>
                  <SelectedIcon />
                </span>
              ) : null}
            </li>
          )
        })}
        {filteredOptions.length === 0 && (
          <li className='no-results'>
            {options.length > 0 ? noResultsText : noOptionsText}
          </li>
        )}
      </ul>
    )
  }

  return (
    <section className={`${cssClass} ${getClasses()}`} style={{ width }}>
      <label htmlFor={id}>
        <span>{label}</span>
        <span>{secondLabel}</span>
      </label>
      <div>
        <input
          id={`${id}-search`}
          name={`${id}-search`}
          autoComplete='off'
          value={searchText}
          onChange={({ target }) => {

            !open && handleOpen()
            !disabledSearch && handleSearchChange(target?.value)
          }}
          onKeyDown={handleKey}
          placeholder={placeholder}
          onClick={() => (open ? handleClose() : handleOpen())}
          onBlur={(e) => {
            handleClose()
            onLostFocus(e)
          }}
          disabled={disabled}
          onFocus={onFocus}
          tabIndex={tabIndex}
        />
        <div className='icons'>
          <span
            onClick={() => handleChange()}
            onMouseDown={(e) => e.preventDefault()}
            hidden={!selectedOption || required}
          >
            <EraseIcon />
          </span>
          <span>
            <OpenIcon />
          </span>
        </div>
        {open ? getListOfOptions() : null}
      </div>
      <div className='messages'>
        <span>{message}</span>
        <span hidden={!showRequired}>{requiredText}</span>
      </div>
      <select
        id={id}
        value={selectedOption || ''}
        options={options}
        onChange={emptyFn}
        hidden
      >
        <option />
        {options.map((option, index) => (
          <option value={option[valueField]} key={`${id}-option-${index}`}>
            {option[labelField]}
          </option>
        ))}
      </select>
    </section>
  )
}

export default SelectBox
