import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { useQuery } from '@tanstack/react-query';

import { regex } from '@23people/moonbase-validators-v3';

import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import Page from '../../components/Page';
import TextField from '../../components/text-field';
import { Form, useForm } from '../../components/form';
import Steps from '../../components/steps';

import { labels, endpoint } from '.';
import apiRequest from '../../lib/api-request';
import FlexLayout from '../../components/flex-layout';
import SelectRest from '../../components/select-rest';
import SelectBox from '../../components/select-box';
import SelectArray from '../../components/select-array';
import TextArea from '../../components/text-area';
import { api, fetchAll } from '../../api';
import Multivalue from '../../components/multivalue';
import MultiSelectRest from '../../components/multiselect';

export const fetchData = async (url) => {

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);

  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json;
};

export default function Edit({ endpoint, labels }) {

  const { id } = useParams();
  const [country, setCountry] = useState({});
  const [position, setPosition] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [startDate, setStartDate] = useState('')
  const [requirementType, setRequirementType] = useState('');
  const [skills, setSkills] = useState([]);
  const [optionalSkills, setOptionalSkills] = useState([]);

  const [requiredSkillsText, setRequiredSkillsText] = useState('');
  const [optionalSkillsText, setOptionalSkillsText] = useState('');

  const [skillsList, setSkillsList] = useState([]);
  const [skillsNamesList, setSkillsNamesList] = useState([]);
  const [optionalSkillsNamesList, setOptionalSkillsNamesList] = useState([]);

  const [salaryStart, setSalaryStart] = useState('');
  const [salaryEnd, setSalaryEnd] = useState('');

  const [workingCondition, setWorkingCondition] = useState('');
  const [englishLevel, setEnglishLevel] = useState('');
  const [duration, setDuration] = useState('');
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');
  const [publication, setPublication] = useState('');
  const [benefits, setBenefits] = useState([]);
  const [positionLevel, setPositionLevel] = useState('');

  const [customer, setCustomer] = useState();
  const [manager, setManager] = useState();
  const [team, setTeam] = useState();
  const [customerReference, setCustomerReference] = useState('');

  const [managersOptions, setManagersOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);

  const [steps, setSteps] = useState([
    { name: 'Posición', state: 'current' },
    { name: 'Detalles de Publicación' },
    { name: 'Pre-Visualización' }
  ]);

  const [valid, isValid] = useState(false);

  //TODO: Change this function to valid all fields
  const validForm = () => {
    return !!position && valid;  //check if form is valid. Required + Valid Fields
  }

  const [actions] = useForm(endpoint, validForm);

  const { data, isError, isLoading, status } = useQuery(
    [`${endpoint}/${id}`],
    () => fetchData(`${endpoint}/${id}`)
  );

  useEffect(() => {
    if (status === 'success') {
      setPosition(data.position);
      setPositionLevel(data.positionLevel._id);
      setCustomer(data.customer._id);
      setRequirementType(data.requirementType._id);
      setStartDate(data.startDate);

      setBenefits(data?.benefits?.map(benefit => ({
        id: benefit._id,
        name: benefit.name
      })));

      setEnglishLevel(data.englishLevel._id);
      setQuantity(data.applicantsNumber);
      setCountry(data.country._id);
      setTeam(data.team);
      setDuration(data.duration);
      setWorkingCondition(data.workingCondition._id);
      setSalaryStart(data.salaryRange.split(' - ')[0]);
      setSalaryEnd(data.salaryRange.split('-')[1]);
      setDescription(data.description);
      setPublication(data.publication);
      setCustomerReference(data.customerReference);
      setCurrency(data.currency);
      setSkills(data?.skills?.map(skill => skill._id));
      setOptionalSkills(data.optionalSkills?.map(skill => skill?._id) || []);
      setSkillsNamesList(data?.skills?.map(skill => skill.name));
      setOptionalSkillsNamesList(data?.optionalSkills?.map(skill => skill.name));

      const managersWithFullName = data.customer.managers.map(manager => {
        return {
          ...manager,
          fullname: `${manager.name} ${manager.lastName}`
        }
      })
      
      setManagersOptions(managersWithFullName);
      setTeamsOptions(data.customer.teams);
      setManager(data.managers[0]);

    }
  }, [data, status]);

  const skillsQuery = useQuery({
    queryKey: [api.skills],
    queryFn: () => fetchAll(api.skills)
  });


  useEffect(() => {
    if (skillsQuery.status === 'success') {
      setSkillsList(skillsQuery.data.result);
    }
  }, [skillsQuery]);


  const handlePositionLevelChange = (id, positionLevel) => {
    setPositionLevel(id);
  }

  const handleEnglishLevelChange = (id, englishLevel) => {
    setEnglishLevel(id);
  }

  const handleCountryChange = (id, country) => {
    setCountry(id);
  }

  const handleWorkingConditionChange = (id, workingCondition) => {
    setWorkingCondition(id);
  }

  const handleRequirementTypeChange = (id, requirementType) => {
    setRequirementType(id);
  }


  const handleRequiredSkillsChange = values => {
    const skillsIds = [];
    const skillsNames = [];

    values.forEach(el => {
      const skill = skillsList.find(skill => skill.name === el);
      
      if (skill) {
        skillsIds.push(skill._id);
        skillsNames.push(skill.name);
      }
    })

    setSkillsNamesList(skillsNames);
    setSkills(skillsIds);
  }

  const handleOptionalSkillsChange = values => {
    const skillsIds = [];
    const skillsNames = [];

    values.forEach(el => {
      const skill = skillsList.find(skill => skill.name === el);
      
      if (skill) {
        skillsIds.push(skill._id);
        skillsNames.push(skill.name);
      }
    })

    setOptionalSkillsNamesList(skillsNames);
    setOptionalSkills(skillsIds);
  }

  if (isLoading) return 'Cargando...';

  if (isError) return 'Ocurrió un error';

  const handleCustomerChange = (id, customer) => {
    setCustomer(id);

    const managersWithFullName = customer.managers.map(manager => {
      return {
        ...manager,
        fullname: `${manager.name} ${manager.lastName}`
      }
    })

    setManagersOptions(managersWithFullName);
    setTeamsOptions(customer.teams);
    setCustomerReference(customer.about);
    setManager(customer.managers[0]);
    setTeam('');
  }

  const handleValidation = (value) => {
    isValid(value.valid);

  };

  const onChangeBenefits = (items) => {
    setBenefits(items);
  }

  const handleSubmit = async () => {
    const json = {
      position: position,
      applicantsNumber: parseInt(quantity),
      positionLevel: positionLevel,
      englishLevel: englishLevel,
      country: country,
      workingCondition: workingCondition,
      requirementType: requirementType,
      duration: duration,
      currency: currency,
      salaryRange: `${salaryStart} - ${salaryEnd}`,
      minSalary: salaryStart,
      maxSalary: salaryEnd,
      customer: customer,
      managers: manager,
      team: team,
      skills: skills,
      optionalSkills: optionalSkills,
      benefits: benefits.map(benefit => benefit.id),
    };

    await actions.submitUpdate(id, json, 'position')
  }

  const handleSteps = stepName => {

    // TODO: Refactor this algorithm. Can be a lot simpler
    const newSteps = steps.map(step => {
      return { name: step.name, state: step.state === 'completed' ? 'completed' : '' };
    }
    );

    const index = newSteps.findIndex(step => step.name === stepName);

    newSteps[index] = { name: stepName, state: newSteps[index].state === 'completed' ? 'completed' : 'current' }

    setSteps(newSteps);

  };

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.edit, link: '' }]}>

      <Steps labels={steps} onClick={handleSteps} />

      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            {isError && <span style={{ color: 'red' }}>El registro no existe</span>}

            <TextField
              id='position'
              label='Posición'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={position}
              onChange={setPosition}
              onValidation={handleValidation}
              pattern={regex.TEXT}
              enabled={true}
              validationText="Por favor ingrese un nombre válido"
            />

            <FlexLayout>
              <TextField
                id='quantity'
                label='Cantidad'
                secondLabel="*"
                maxLength='2'
                required={true}
                requiredText="El campo es requerido"
                value={quantity}
                onChange={setQuantity}
                onValidation={handleValidation}
                pattern={regex.NUMBER}
                enabled={true}
                validationText="Por favor ingrese un número válido"
              />

              <SelectRest
                id='requirementType'
                label='Tipo'
                required={true}
                secondLabel='*'
                placeholder='Tipo Requerimiento'
                value={requirementType}
                onChange={handleRequirementTypeChange}
                onValidation={handleValidation}
                width='220px'
                valueField='_id'
                labelField='name'
                endpoint={api.requirementTypes}
              />
            </FlexLayout>

            {/* TODO: get the data from the server and show it in the form */}
            <SelectRest
              id='customer'
              label='Cliente'
              required={true}
              requiredText="El campo es requerido"
              secondLabel='*'
              placeholder='Seleccione un cliente'
              value={customer}
              onChange={handleCustomerChange}
              valueField='_id'
              labelField='name'
              endpoint={api.customers}
            />

            <SelectBox
              id='managers'
              label='Manager'
              required={true}
              secondLabel='*'
              placeholder='Seleccione un manager'
              options={managersOptions}
              labelField='fullname'
              valueField='_id'
              value={manager}
              onChange={setManager}
            />

            <SelectArray
              id='teams'
              label='Equipo'
              secondLabel='*'
              placeholder='Seleccione un equipo'
              options={teamsOptions}
              value={team}
              onChange={setTeam}
            />

            <SelectRest
              id='positionLevel'
              label='Nivel'
              required={true}
              requiredText="El campo es requerido"
              secondLabel='*'
              placeholder='Entry, Mid Level, Senior'
              value={positionLevel}
              onChange={handlePositionLevelChange}
              valueField='_id'
              labelField='name'
              endpoint={api.positionLevels}
            />

            <SelectRest
              id='workingCondition'
              label='Condiciones de Trabajo'
              required={true}
              requiredText="El campo es requerido"
              secondLabel='*'
              placeholder='Remoto, Híbrido o Presencial'
              value={workingCondition}
              onChange={handleWorkingConditionChange}
              valueField='_id'
              labelField='name'
              endpoint={api.workingConditions}
            />

            <SelectRest
              id='english-level'
              label='Nivel Inglés'
              required={true}
              requiredText="El campo es requerido"
              secondLabel='*'
              placeholder='Desde C2 a A1'
              value={englishLevel}
              onChange={handleEnglishLevelChange}
              valueField='_id'
              labelField='name'
              endpoint={api.englishLevels}
            />

            <SelectRest
              id='country'
              label='País de Residencia'
              required={true}
              requiredText="El campo es requerido"
              secondLabel='*'
              placeholder='País desde donde se presta el servicio'
              value={country}
              onChange={handleCountryChange}
              valueField='_id'
              labelField='name'
              endpoint={api.countries}
            />

            <TextField
              id='quantity'
              label='Duración'
              value={duration}
              onChange={setDuration}
              pattern={regex.TEXT}
              placeholder='Duración proyectada: 1 año'
              validationText='Por favor ingrese un nombre válido'
              width='100%'
            />

            <FlexLayout>
              <TextField
                id='range1'
                label='Salario mínimo'
                value={salaryStart}
                onChange={setSalaryStart}
                pattern={regex.NUMBER}
                validationText='Por favor ingrese un número válido'
                width='160px'
              />

              <TextField
                id='range2'
                label='Salario máximo'
                value={salaryEnd}
                onChange={setSalaryEnd}
                pattern={regex.NUMBER}
                validationText='Por favor ingrese un número válido'
                width='160px'
              />

              <SelectRest
                id='currency'
                label='Moneda'
                placeholder='CLP, USD, UF'
                value={currency}
                onChange={setCurrency}
                valueField='_id'
                labelField='name'
                endpoint={api.currencies}
              />

            </FlexLayout>

            <Multivalue
              id='required-skills'
              label='Requeridos'
              onChange={handleRequiredSkillsChange}
              value={skillsNamesList}
            />

            <Multivalue
              id='optional-skills'
              label='Deseables'
              value={optionalSkillsNamesList}
              onChange={handleOptionalSkillsChange}
            />

            <MultiSelectRest
              id='selectBenefits'
              label='Beneficios'
              placeholder='Seleccione un beneficio'
              onChange={onChangeBenefits}
              endpoint={api.benefits}
              values={benefits}
            />
          </main>

          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}